<template>
    <section id="footer-body">
        <div class="container">
            <div class="wrapper-top" v-if="isMobile == false">
                <div class="footer-nav">
                    <button class="btn risk-disclosure" :class="setActiveRisk" v-t="'footer.compliance'"
                        @click="component = 'RiskDisclosure'"></button>
                    <button class="btn brand-entity" :class="setActiveBrand" v-t="'footer.brand-entity'"
                        @click="component = 'BrandEntity'"></button>
                    <button class="btn client-notice" :class="setActiveClient" v-t="'footer.client-notice'"
                        @click="component = 'ClientNotice'"></button>
                </div>
                <div class="content">
                    <component :is="component"></component>
                </div>
                <div class="border-btm"></div>
            </div>
            <div class="wrapper-top-mobile" v-else>
                <div class="border-btm-first"></div>
                <div class="container-navigation ">
                    <div class="txt-wrapper" @click="toggleRiskDisclosure">
                        <p v-t="'footer.compliance'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="riskDisclosureSection">
                        <p class="description" v-t="'footer.body.compliance-description-one'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-two'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-three'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-four'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-five'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-six'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-seven'"></p>
                        <p class="description" v-t="'footer.body.compliance-description-eight'"></p>
                    </div>
                </div>
                <div class="border-btm"></div>
                <div class="container-navigation ">
                    <div class="txt-wrapper" @click="toggleBrandEntity">
                        <p v-t="'footer.brand-entity'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="brandEntitySection">
                        <p class="description" v-t="'footer.body.brand-description-one'"></p>
                        <p class="description" v-t="'footer.body.brand-description-two'"></p>
                        <!--p class="description" v-t="'footer.body.brand-description-three'"></p-->
                    </div>
                </div>
                <div class="border-btm"></div>
                <div class="container-navigation ">
                    <div class="txt-wrapper" @click="toggleClientNotice">
                        <p v-t="'footer.client-notice'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="clientNoticeSection">
                        <p class="description" v-t="'footer.body.client-description-one'"></p>
                        <p class="description" v-t="'footer.body.client-description-two'"></p>
                        <p class="description" v-t="'footer.body.client-description-three'"></p>
                        <p class="description" v-t="'footer.body.client-description-four'"></p>
                    </div>
                </div>
                <div class="border-btm"></div>
                <div class="container-navigation ">
                    <div class="txt-wrapper" @click="toggleDooNotice">
                        <p v-t="'footer.entities-notice'"></p>
                        <img class="icon-dropdown"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown_dark.svg"
                            alt="dropdown">
                    </div>
                    <div class="container-dropdown" v-if="riskSection">
                        <div class="entities-container" style="padding:0px;" v-if="riskSection === true">
                            <div class="entities-content">
                                <div class="ellish-container container-detail container-left">
                                    <p class="ellish-title title" v-t="'footer.body.ellish'"></p>
                                    <p class="ellish-desc desc" v-t="'footer.body.ellish-one'"></p>
                                    <p class="ellish-desc desc" v-t="'footer.body.ellish-two'"></p>
                                    <p class="ellish-desc desc" v-t="'footer.body.ellish-three'"></p>
                                </div>
                                <div class="clearing-container container-detail">
                                    <p class="clearing-title title" v-t="'footer.body.clearing'"></p>
                                    <p class="clearing-desc desc" v-t="'footer.body.clearing-one'"></p>
                                    <p class="clearing-desc desc" v-t="'footer.body.clearing-two'"></p>
                                </div>
                                <div class="financialAus-container container-detail container-left">
                                    <p class="financialAus-title title" v-t="'footer.body.financialAus'"></p>
                                    <p class="financialAus-desc desc" v-t="'footer.body.financialAus-one'"></p>
                                    <p class="financialAus-desc desc" v-t="'footer.body.financialAus-two'"></p>
                                </div>
                                <div class="exchange-container container-detail">
                                    <p class="exchange-title title" v-t="'footer.body.exchange'"></p>
                                    <p class="exchange-desc desc" v-t="'footer.body.exchange-one'"></p>
                                    <p class="exchange-desc desc" v-t="'footer.body.exchange-two'"></p>
                                </div>
                                <div class="financialHk-container container-detail container-left">
                                    <p class="financialHk-title title" v-t="'footer.body.financialHk'"></p>
                                    <p class="financialHk-desc desc" v-t="'footer.body.financialHk-one'"></p>
                                    <p class="financialHk-desc desc" v-t="'footer.body.financialHk-two'"></p>
                                </div>
                                <div class="dooWealth-container container-detail">
                                    <p class="dooWealth-title title" v-t="'footer.body.dooWealth'"></p>
                                    <p class="dooWealth-desc desc" v-t="'footer.body.dooWealth-one'"></p>
                                    <p class="dooWealth-desc desc" v-t="'footer.body.dooWealth-two'"></p>
                                </div>
                                <div class="dooHolding-container container-detail container-left">
                                    <p class="dooHolding-title title" v-t="'footer.body.dooHolding'"></p>
                                    <p class="dooHolding-desc desc" v-t="'footer.body.dooHolding-one'"></p>
                                    <p class="dooHolding-desc desc" v-t="'footer.body.dooHolding-two'"></p>
                                </div>
                                <div class="dooProperties-container container-detail">
                                    <p class="dooProperties-title title" v-t="'footer.body.dooProperties'"></p>
                                    <p class="dooProperties-desc desc" v-t="'footer.body.dooProperties-one'"></p>
                                    <p class="dooProperties-desc desc" v-t="'footer.body.dooProperties-two'"></p>
                                </div>
                                <div class="dooConsulting-container container-detail container-left">
                                    <p class="dooConsulting-title title" v-t="'footer.body.dooConsulting'"></p>
                                    <p class="dooConsulting-desc desc" v-t="'footer.body.dooConsulting-one'"></p>
                                    <p class="dooConsulting-desc desc" v-t="'footer.body.dooConsulting-two'"></p>
                                </div>
                                <div class="dooConsulting-container container-detail container-left">
                                    <p class="dooConsulting-title title" v-t="'footer.body.dooFintechHKLimited'"></p>
                                    <p class="dooConsulting-desc desc" v-t="'footer.body.dooFintechHKLimited-one'"></p>
                                </div>
                                <div class="financialLabuan-container container-detail">
                                    <p class="financialLabuan-title title" v-t="'footer.body.financialLabuan'"></p>
                                    <p class="financialLabuan-desc desc" v-t="'footer.body.financialLabuan-one'"></p>
                                    <p class="financialLabuan-desc desc" v-t="'footer.body.financialLabuan-two'"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="notice-nav" @click="toggleDooNotice" v-if="isMobile == false">
                <div class="btn entities-notice">
                    <p class="brand-entities" v-t="'footer.entities-notice'"></p>
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close-Light-Grey.svg"
                        alt="close" v-show="this.riskSection == true">
                    <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown-Light-Grey.svg"
                        alt="close" v-show="this.riskSection == false">
                </div>
                <div class="border-btm"></div>
            </div>

            <div name="height-transition" mode="out-in" v-if="isMobile == false">
                <div class="entities-container" v-if="riskSection === true">
                    <div class="entities-content">

                        <div class="ellish-container container-detail container-left">
                            <p class="ellish-title title" v-t="'footer.body.ellish'"></p>
                            <p class="ellish-desc desc" v-t="'footer.body.ellish-one'"></p>
                            <p class="ellish-desc desc" v-t="'footer.body.ellish-two'"></p>
                            <p class="ellish-desc desc" v-t="'footer.body.ellish-three'"></p>
                        </div>
                        <div class="clearing-container container-detail">
                            <p class="clearing-title title" v-t="'footer.body.clearing'"></p>
                            <p class="clearing-desc desc" v-t="'footer.body.clearing-one'"></p>
                            <p class="clearing-desc desc" v-t="'footer.body.clearing-two'"></p>
                        </div>
                        <div class="financialAus-container container-detail container-left">
                            <p class="financialAus-title title" v-t="'footer.body.financialAus'"></p>
                            <p class="financialAus-desc desc" v-t="'footer.body.financialAus-one'"></p>
                            <p class="financialAus-desc desc" v-t="'footer.body.financialAus-two'"></p>
                        </div>
                        <div class="exchange-container container-detail">
                            <p class="exchange-title title" v-t="'footer.body.exchange'"></p>
                            <p class="exchange-desc desc" v-t="'footer.body.exchange-one'"></p>
                            <p class="exchange-desc desc" v-t="'footer.body.exchange-two'"></p>
                        </div>
                        <div class="financialHk-container container-detail container-left">
                            <p class="financialHk-title title" v-t="'footer.body.financialHk'"></p>
                            <p class="financialHk-desc desc" v-t="'footer.body.financialHk-one'"></p>
                            <p class="financialHk-desc desc" v-t="'footer.body.financialHk-two'"></p>
                        </div>
                        <div class="dooWealth-container container-detail">
                            <p class="dooWealth-title title" v-t="'footer.body.dooWealth'"></p>
                            <p class="dooWealth-desc desc" v-t="'footer.body.dooWealth-one'"></p>
                            <p class="dooWealth-desc desc" v-t="'footer.body.dooWealth-two'"></p>
                        </div>
                        <div class="dooHolding-container container-detail container-left">
                            <p class="dooHolding-title title" v-t="'footer.body.dooHolding'"></p>
                            <p class="dooHolding-desc desc" v-t="'footer.body.dooHolding-one'"></p>
                            <p class="dooHolding-desc desc" v-t="'footer.body.dooHolding-two'"></p>
                        </div>
                        <div class="dooProperties-container container-detail">
                            <p class="dooProperties-title title" v-t="'footer.body.dooProperties'"></p>
                            <p class="dooProperties-desc desc" v-t="'footer.body.dooProperties-one'"></p>
                            <p class="dooProperties-desc desc" v-t="'footer.body.dooProperties-two'"></p>
                        </div>
                        <div class="dooConsulting-container container-detail container-left">
                            <p class="dooConsulting-title title" v-t="'footer.body.dooConsulting'"></p>
                            <p class="dooConsulting-desc desc" v-t="'footer.body.dooConsulting-one'"></p>
                            <p class="dooConsulting-desc desc" v-t="'footer.body.dooConsulting-two'"></p>
                        </div>
                        <div class="dooConsulting-container container-detail container-left">
                            <p class="dooConsulting-title title" v-t="'footer.body.dooFintechHKLimited'"></p>
                            <p class="dooConsulting-desc desc" v-t="'footer.body.dooFintechHKLimited-one'"></p>
                        </div>
                        <div class="financialLabuan-container container-detail">
                            <p class="financialLabuan-title title" v-t="'footer.body.financialLabuan'"></p>
                            <p class="financialLabuan-desc desc" v-t="'footer.body.financialLabuan-one'"></p>
                            <p class="financialLabuan-desc desc" v-t="'footer.body.financialLabuan-two'"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import RiskDisclosure from './TNC/RiskDisclosure'
import BrandEntity from './TNC/BrandEntity'
import ClientNotice from './TNC/ClientNotice'

export default {

    components: {
        RiskDisclosure,
        BrandEntity,
        ClientNotice
    },
    computed: {
        setActiveRisk() {
            return this.component == 'RiskDisclosure' ? 'active' : ''
        },
        setActiveBrand() {
            return this.component == 'BrandEntity' ? 'active' : ''
        },
        setActiveClient() {
            return this.component == 'ClientNotice' ? 'active' : ''
        }
    },
    data() {
        return {
            component: 'RiskDisclosure',
            riskSection: true,
            isMobile: false,
            riskDisclosureSection: false,
            brandEntitySection: false,
            clientNoticeSection: false
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        toggleRiskDisclosure() {
            this.riskDisclosureSection = !this.riskDisclosureSection
        },
        toggleBrandEntity() {
            this.brandEntitySection = !this.brandEntitySection
        },
        toggleClientNotice() {
            this.clientNoticeSection = !this.clientNoticeSection
        },
        toggleDooNotice() {
            this.riskSection = !this.riskSection
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>

<style lang="less" scoped>
@import '../../../less/mediaQuery.less';
.height-transition-enter-from,
.height-transition-leave-to {
    height: 0;
}

.height-transition-enter-active,
.height-transition-leave-active {
    transition: height 1s ease;
}

.height-transition-enter-to,
.height-transition-leave-from {
    height: 3040px;
}



#footer-body {
    background-color: #000000;

    .wrapper-top-mobile {
        margin: 0;

        .container-navigation {
            .txt-wrapper {
                display: flex;
                justify-content: space-between;
                cursor:pointer;

                p {

                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                }
            }

            .container-dropdown {
                .description {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    text-align: left;
                    padding-top: 16px;

                    p {
                        padding-bottom: 16px;
                    }
                }
            }
        }

        .border-btm-first {
            padding-top: 40px;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .border-btm-last {
            padding-top: 40px;
            margin-top: 16px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }

    }

    .wrapper-top {
        padding: 40px 0px 20px;

        .footer-nav {
            text-align: left;
            padding: 0 0 20px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: start;

            .active::after {
                content: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close-Red.svg);
                position: absolute;
                transform: translate(-50%, -50%);
                top: 150%;
                left: 50%;
                width: 20px;
                height: 12px;
            }

            .btn {
                background-color: transparent;
                border: none;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
                position: relative;
                margin-right: 120px;
            }

            .btn:hover {
                cursor: pointer;
            }
        }

        section {
            padding:0;
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }

    .notice-nav-mobile {
        padding: 0 16px;

        .border-btm {
            display: flex;
            padding: 0 160px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .btn {
            display: flex;

            img {
                width: 24px;
                height: 24px;
                padding-bottom: 20px;
                padding-top: 20px;
            }

            p {
                margin: 0;
                padding-bottom: 20px;
                padding-top: 20px;
                text-align: left;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
            }
        }

        .btn:hover {
            cursor: pointer;
        }
    }

    .notice-nav {

        .border-btm {
            display: flex;
            padding: 0 160px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .btn {
            display: flex;

            img {
                width: 24px;
                height: 24px;
                margin-top: -1px;
                margin-left: 10px;
            }

            p {
                margin: 0;
                padding-bottom: 20px;
                text-align: left;

                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #9e9e9f;
            }
        }

        .btn:hover {
            cursor: pointer;
        }
    }

    .entities-container {
        padding: 0 0 24px;

        @media @mobile-screen {
            padding: 0 16px;
        }

        .entities-content {
            display: grid;
            grid-column-gap: 28px;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;

            @media @mobile-screen {
                padding-top: 16px;
                grid-row-gap: 24px;
                grid-template-columns: repeat(1, 1fr)
            }


            .container-detail {
                p {
                    text-align: left;
                }

                .title {
                    margin: 24px 0 16px 0;

                    @media @mobile-screen {
                        margin: 0px 0 16px 0;
                    }


                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #ffff;
                }

                .desc {

                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #9e9e9f;
                    margin-bottom: 16px;
                }
            }

            .container-left {
                padding-right: 28px;
            }
        }

        .border-btm {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }


}
</style>