<template>
    <section id="footer-bottom">
        <div class="container">
            <p class="footer-bottom-txt" v-html="$t('footer.footerBottom', { year })"></p>
        </div>
    </section>
</template>

<script setup>

const year = new Date().getFullYear();

</script>

<style lang="less" scoped>
#footer-bottom {
    background-color: #000000;
    display: flex;
    flex-direction: column;


    .footer-bottom-txt {
        font-family: SegoeUI;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #9e9e9f;
        padding: 16px 0;
        background: #000;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

}
</style>