<template>
    <section id="footer">
        <FooterBanner></FooterBanner>
        <FooterTop></FooterTop>
        <FooterBody></FooterBody>
        <FooterBottom></FooterBottom>
    </section>
</template>

<script>
import FooterBanner from './FooterModels/FooterBanner.vue'
import FooterTop from './FooterModels/FooterTop.vue'
import FooterBody from './FooterModels/FooterBody.vue'
import FooterBottom from './FooterModels/FooterBottom.vue'


export default {
    components: {
        FooterBanner,
        FooterTop,
        FooterBody,
        FooterBottom
    }
}
</script>

<style lang="less" scoped >
@import '../../less/mediaQuery.less';
#footer{
    margin-top: 160px;
    @media @mobile-screen {
        margin-top: 80px;
    }
}</style>